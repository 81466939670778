import ApiIcon from "@mui/icons-material/Api";
import ArticleIcon from "@mui/icons-material/Article";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RoutesConst from "../../consts/RoutesConst.js";
import GeneralStrings from "../../strings/GeneralStrings.json";

const NAV_STRINGS = GeneralStrings.DASHBOARD.NAV;

export const dashboardItem = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.DEFAULT}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.DASHBOARD} />
    </ListItem>
  </>
);

export const applicationItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.ARTISTS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <KeyboardVoiceIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.ARTISTS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.BLOG.DEFAULT}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.BLOG} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.FAQ}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <QuestionAnswerIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.FAQ} />
    </ListItem>
    <ListItem className="dashboard-nav-list-item">
      <ListItemIcon className="dashboard-nav-icon">
        <StorefrontIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.SERVICES} />
    </ListItem>
    <ListItem className="dashboard-nav-list-item">
      <ListItemIcon className="dashboard-nav-icon">
        <SurroundSoundIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.SOUND_KITS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.USERS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.USERS} />
    </ListItem>
  </>
);

export const utilitiesItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <SettingsSuggestIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.ACCOUNT_SETTINGS} />
    </ListItem>
    <ListItem className="dashboard-nav-list-item">
      <ListItemIcon className="dashboard-nav-icon">
        <ApiIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.API_DOCS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.HELP}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <HelpIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.HELP} />
    </ListItem>
  </>
);
