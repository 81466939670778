import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import GeneralConst from "../../consts/GeneralConst";
import logoBlack from "../../images/logos/logoBlack.png";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getUserAlbums } from "../../utilities/SpotifyHelper";
import "./Projects.scss";

const TITLE = `Projects | ${GeneralConst.SITE_NAME}`;

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const projectStrings = GeneralStrings.PROJECTS;

  const renderAlbums = () => {
    if (_.isEmpty(projects) || !_.some(projects, ["album_group", "album"])) {
      return null;
    }

    return (
      <>
        <h4 className="project-group-header margin-bottom-40">
          {projectStrings.ALBUMS}
        </h4>
        <div className="row">
          {projects.map((project) => {
            return (
              project.album_type === "album" &&
              project.album_group === "album" && (
                <div
                  className="col-sm-12 col-md-3"
                  key={"project-" + project.name}
                >
                  <ProjectCard project={project} />
                </div>
              )
            );
          })}
        </div>
      </>
    );
  };

  const renderSinglesAndEPs = () => {
    if (_.isEmpty(projects) || !_.some(projects, ["album_group", "single"])) {
      return null;
    }

    return (
      <>
        <h4 className="project-group-header margin-bottom-40">
          {projectStrings.SINGLES_AND_EPS}
        </h4>
        <div className="row">
          {projects.map((project) => {
            return (
              project.album_type === "single" &&
              project.album_group === "single" && (
                <div
                  className="col-sm-6 col-md-4 col-lg-3"
                  key={"project-" + project.name}
                >
                  <ProjectCard project={project} />
                </div>
              )
            );
          })}
        </div>
      </>
    );
  };

  const renderCompilation = () => {
    if (
      _.isEmpty(projects) ||
      !_.some(projects, ["album_group", "compilation"])
    ) {
      return null;
    }

    return (
      <>
        <h4 className="project-group-header margin-bottom-40">
          {projectStrings.APPEARS_ON}
        </h4>
        <div className="row">
          {projects.map((project) => {
            return (
              project.album_group === "compilation" &&
              (project.album_type === "album" ||
                project.album_type === "single") && (
                <div
                  className="col-sm-12 col-md-3"
                  key={"project-" + project.name}
                >
                  <ProjectCard project={project} />
                </div>
              )
            );
          })}
        </div>
      </>
    );
  };

  const renderAppearsOn = () => {
    if (
      _.isEmpty(projects) ||
      !_.some(projects, ["album_group", "appears_on"])
    ) {
      return null;
    }

    return (
      <>
        <h4 className="project-group-header margin-bottom-40">
          {projectStrings.APPEARS_ON}
        </h4>
        <div className="row">
          {projects.map((project) => {
            return (
              project.album_group === "album" &&
              (project.album_type === "album" ||
                project.album_type === "single") && (
                <div
                  className="col-sm-12 col-md-3"
                  key={"project-" + project.name}
                >
                  <ProjectCard project={project} />
                </div>
              )
            );
          })}
        </div>
      </>
    );
  };

  useEffect(() => {
    getUserAlbums().then((res) => {
      setProjects(res?.items);
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="projects">
        <div className="blog section section-our-clients-freebie">
          <div className="container">
            <div className="title-area">
              <h2>{projectStrings.PROJECTS}</h2>
              <div className="separator">
                <img src={logoBlack} className="seperator-img" alt="logo" />
              </div>
              <h4 className="margin-top-40">{projectStrings.DESCRIPTION}</h4>
            </div>
            <div className="projects-container container">
              {renderAlbums()}
              {renderSinglesAndEPs()}
              {renderCompilation()}
              {renderAppearsOn()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Projects;
