import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardCover from "@mui/joy/CardCover";
import Typography from "@mui/joy/Typography";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ProjectCard.scss";

const ProjectCard = ({ project }) => {
  const projectTypes = GeneralStrings.PROJECTS.PROJECT_TYPES;

  const renderProjectType = () => {
    if (project?.album_group === "single" && project?.total_tracks >= 4) {
      return projectTypes.EP;
    } else if (project?.album_group === "single" && project?.total_tracks < 4) {
      return projectTypes.SINGLE;
    } else {
      return projectTypes.ALBUM;
    }
  };

  return !project ? null : (
    <div className="project-card">
      <a href={project.external_urls.spotify} target="_blank" rel="noreferrer">
        <Card className="card-container">
          <CardCover>
            <img
              src={project.images[0].url}
              loading="lazy"
              alt={`${project.name} Cover Art`}
            />
          </CardCover>
          <CardCover className="card-overlay" />
          <CardContent sx={{ justifyContent: "flex-end" }}>
            <Typography level="title-md" textColor="white">
              {project.name}
            </Typography>
            <Typography textColor="neutral.300">
              {renderProjectType()}
            </Typography>
          </CardContent>
        </Card>
      </a>
    </div>
  );
};

export default ProjectCard;
