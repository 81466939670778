// TODO: Setup jsconfig for shorter directories

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Artists from "../components/Artists/Artists";
import BeatStore from "../components/BeatStore/BeatStore";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import HomepageNavbar from "../components/HomepageNavbar/HomepageNavbar";
import Pricing from "../components/Pricing/Pricing";
import TitleScreen from "../components/TitleScreen/TitleScreen";
import YoutubeChannel from "../components/YoutubeChannel/YoutubeChannel";
import GeneralConst from "../consts/GeneralConst";
import AVAService from "../services/AVAService";

const TITLE = `Home | ${GeneralConst.SITE_NAME}`;

const Homepage = () => {
  useEffect(() => {
    AVAService.addSiteView(GeneralConst.DOMAIN_NAME);
  }, []);

  return (
    <div className="homepage">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <TitleScreen />
      <BeatStore />
      <Pricing />
      <Artists />
      <YoutubeChannel />
      <Contact />
      <Footer />
    </div>
  );
};

export default Homepage;
