import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import ArtistService from "../../../services/ArtistService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper";
import "./AddArtistModal.scss";

const AddArtistModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const artistsStrings = GeneralStrings.DASHBOARD.ARTISTS;

  const handleSubmit = (formValues) => {
    setIsLoading(true);

    ArtistService.addArtist(formValues)
      .then(() => {
        setSuccessMessage(AlertStrings.ADD_ARTIST_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.ADD_ARTIST_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        setIsLoading(false);
        toggle();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="add-artist-modal"
    >
      <ModalHeader toggle={toggle}>{artistsStrings.ADD_ARTIST}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            name: null,
            spotifyId: null,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25 ">
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.NAME}</FormLabel>
              <Field
                name="name"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="name"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.SPOTIFY_ID}</FormLabel>
              <Field
                name="spotifyId"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="spotifyId"
              />
            </FormControl>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="success"
                disabled={isLoading}
                className="text-lighter margin-left-auto"
              >
                {GeneralStrings.FORM.SUBMIT}
                {isLoading && (
                  <Spinner color="dark" size="sm" className="margin-left-10" />
                )}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddArtistModal;
