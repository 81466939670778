import React from "react";
import LicensesConst from "../../consts/LicensesConst";
import classNames from "../../utilities/classNames";
import LicenseModal from "./LicenseModal";
import "./PricingCard.scss";

const PricingCard = ({ license }) => {
  const getLicenseIcon = (id) => {
    switch (id) {
      case LicensesConst.LICENSE_OPTIONS.MP3_LICENSE:
        return <i className="fas fa-compact-disc fa-3x" />;
      case LicensesConst.LICENSE_OPTIONS.WAV_LICENSE:
        return <i className="fas fa-headphones fa-3x" />;
      case LicensesConst.LICENSE_OPTIONS.PREMIUM_LICENSE:
        return <i className="fas fa-microphone-lines fa-3x" />;
      case LicensesConst.LICENSE_OPTIONS.EXCLUSIVE:
        return <i className="fas fa-gem fa-3x" />;
      default:
        return <i className="fas fa-play fa-3x" />;
    }
  };

  const getCardStyle = (id) => {
    switch (id) {
      case LicensesConst.LICENSE_OPTIONS.PREMIUM_LICENSE:
        return "premium-card";
      case LicensesConst.LICENSE_OPTIONS.EXCLUSIVE:
        return "exclusive-card";
      default:
        return null;
    }
  };

  return (
    <div {...classNames("pricing-card", getCardStyle(license.id))}>
      {license.id === LicensesConst.LICENSE_OPTIONS.WAV_LICENSE && (
        <div className="ribbon">{LicensesConst.MOST_POPULAR}</div>
      )}
      <div className="pricing-card-header">
        <span className="heading">
          {getLicenseIcon(license.id)}
          <span className="pricing-card-title">{license.id}</span>
        </span>
        <span
          className="pricing-card-price"
          {...classNames(
            "pricing-card-price",
            license.id === LicensesConst.LICENSE_OPTIONS.EXCLUSIVE &&
              "negotiable"
          )}
        >
          {license.id === LicensesConst.LICENSE_OPTIONS.EXCLUSIVE ? (
            license.price
          ) : (
            <>
              <span>$</span> {license.price}
            </>
          )}
        </span>
      </div>
      <div className="pricing-card-details">
        <ul dangerouslySetInnerHTML={{ __html: license.content }} />
      </div>
      <div className="pricing-card-modal-container">
        <LicenseModal
          buttonClassName="btn btn-block btn-lease"
          description={license.description}
          title={license.title}
          notice={LicensesConst.NOTICE}
        />
      </div>
    </div>
  );
};

export default PricingCard;
