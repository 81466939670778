import { Table } from "@mui/joy";
import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import UserService from "../../../services/UserService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import AddUserModal from "./AddUserModal";
import "./UsersPage.scss";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [deleteValue, setDeleteValue] = useState(null);
  const usersStrings = GeneralStrings.DASHBOARD.USERS;

  const toggleAddUserModal = () => setShowAddUserModal(!showAddUserModal);
  const toggleEditUserModal = () => setShowEditUserModal(!showEditUserModal);
  const toggleDeleteUserModal = () =>
    setShowDeleteUserModal(!showDeleteUserModal);

  const addUser = (event) => {
    event.preventDefault();
    toggleAddUserModal();
  };

  const editUser = (event) => {
    setEditValue(event.target.getAttribute("data-value") || null);
    toggleEditUserModal();
  };

  const deleteUser = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    toggleDeleteUserModal();
  };

  const handleDelete = (formData) => {
    const { deleteValue } = formData;

    UserService.deleteUser(deleteValue)
      .then(() => {
        setSuccessMessage(AlertStrings.DELETE_USER_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.DELETE_USER_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        toggleDeleteUserModal();
      });
  };

  useEffect(() => {
    UserService.getAllUsers()
      .then((response) => {
        const users = response?.data;
        setUsers(users);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.GENERAL_ERROR);
        setShowToast(true);
      });
  }, [showToast]);

  // TODO: Figure out issue where Edit User modal is not pre-populating data

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="users-page">
        <DashboardSubPageToolbar
          addButtonText={usersStrings.ADD_USER}
          addFunction={addUser}
          title={usersStrings.USERS}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              hoverRow
              stripe="odd"
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: 400 }}>{usersStrings.EMAIL}</th>
                  <th>{usersStrings.FIRST_NAME}</th>
                  <th>{usersStrings.LAST_NAME}</th>
                  <th style={{ width: 250 }}></th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(users) &&
                  users.map((user) => (
                    <tr key={"user-" + user.email}>
                      <td>{user.email}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td className="flex flex-row">
                        {/* <Button
                          className="dashboard-button margin-right-10 margin-horizonal-center"
                          color="primary"
                          startIcon={<i className="fa-solid fa-pen" />}
                          variant="contained"
                          data-value={user.email}
                          onClick={editUser}
                        >
                          {GeneralStrings.FORM.EDIT}
                        </Button> */}
                        <Button
                          className="dashboard-button margin-horizonal-center"
                          color="error"
                          variant="contained"
                          data-value={user.email}
                          onClick={deleteUser}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Paper>
        </Grid>
        <AddUserModal
          isOpen={showAddUserModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleAddUserModal}
        />
        {/* <EditUserModal
          editValue={editValue}
          isOpen={showEditUserModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleEditUserModal}
        /> */}
        <ConfirmDeleteModal
          deleteMessage={usersStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeleteUserModal}
          deleteValue={deleteValue}
          toggle={toggleDeleteUserModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default UsersPage;
