import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import "./BlogPostCard.scss";

const BlogPostCard = ({ post, showViewButton = true }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    const postId = event.target.getAttribute("data-value") || null;
    navigate({
      pathname: RoutesConst.BLOG.VIEW_POST,
      search: createSearchParams({
        id: postId,
      }).toString(),
    });
  };

  return (
    <div className="blog-post-card">
      <Card
        variant="outlined"
        sx={{ minHeight: 340, width: 275 }}
        className="blog-post-card-container"
      >
        <CardOverflow>
          <AspectRatio ratio="2">
            <img
              src={post?.imgLoc}
              srcSet={post?.imgLoc}
              loading="lazy"
              alt="Blog Post"
            />
          </AspectRatio>
        </CardOverflow>
        <CardContent>
          <Typography level="title-lg" className="margin-top-10">
            {post?.title}
          </Typography>
          <Typography
            level="body-sm"
            {...classNames(
              showViewButton
                ? "margin-bottom-20"
                : "margin-top-5 margin-bottom-40"
            )}
          >
            {post?.subtitle}
          </Typography>
          {showViewButton && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              data-value={post.id}
              onClick={handleClick}
              {...classNames(showViewButton ? "margin-bottom-15" : "")}
            >
              {GeneralStrings.FORM.VIEW_MORE}
            </Button>
          )}
        </CardContent>
        <CardOverflow variant="soft">
          <Divider inset="context" />
          <CardContent orientation="horizontal">
            <EditCalendarIcon />{" "}
            <Typography level="body-sm">{post?.date}</Typography>
          </CardContent>
        </CardOverflow>
      </Card>
    </div>
  );
};

export default BlogPostCard;
