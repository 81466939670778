/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import _ from "lodash";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class ArtistService {
  /**
   * [POST]
   * Submits the new user to the database
   * @param {String} name
   * @param {String} spotifyId
   * @returns {Promise}
   */
  addArtist({ name, spotifyId }) {
    let url = getAPIUrl() + EndpointsConst.API.ARTISTS.ADD_ARTIST;
    return axios.put(url, { name, spotifyId });
  }

  /**
   * [DELETE]
   * Deletes Artist from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteArtist(id) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.ARTISTS.DELETE_ARTIST.replace("{id}", id);
    return axios.delete(url);
  }

  /**
   * [GET]
   * Retrieves all Artists from database
   * @returns {Promise}
   */
  getAllArtists() {
    let url = getAPIUrl() + EndpointsConst.API.ARTISTS.GET_ALL_ARTISTS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves all Artists Spotify IDs from database
   * @returns {Promise}
   */
  getAllArtistsSpotifyIds() {
    let url =
      getAPIUrl() + EndpointsConst.API.ARTISTS.GET_ALL_ARTISTS_SPOTIFY_IDS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves total number of artists
   * @returns {Promise}
   */
  getTotalArtistsCount() {
    let url = getAPIUrl() + EndpointsConst.API.ARTISTS.GET_TOTAL_ARTISTS_COUNT;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves Spotify Data from list of Spotify IDs
   * @param {Array} artistsIDs
   * @returns {Promise}
   */
  retrieveSpotifyData(artistsIDs) {
    let url =
      getAPIUrl() +
      EndpointsConst.SPOTIFY_ENDPOINT.replace("{ids}", _.join(artistsIDs, ","));
    return axios.get(url);
  }
}

export default new ArtistService();
