import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import ArtistService from "../../services/ArtistService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ArtistsCountCard.scss";

const ArtistsCountCard = () => {
  const [artistsCount, setArtistsCount] = useState(0);

  useEffect(() => {
    ArtistService.getTotalArtistsCount().then((res) => {
      setArtistsCount(res.data);
    });
  }, []);

  return (
    <Paper elevation={0} className="artists-count-card">
      <Card>
        <CardContent className="card-content">
          <Grid xs={12}>
            <div className="flex content-container">
              <KeyboardVoiceIcon sx={{ color: "black", fontSize: "50px" }} />
              <div className="margin-left-auto padding-top-5">
                <h2>{artistsCount}</h2>
                <p className="card-description">
                  {GeneralStrings.DASHBOARD.TOTAL_ARTISTS_COUNT}
                </p>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ArtistsCountCard;
