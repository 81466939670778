import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BlogPostCard from "../../components/BlogPostCard/BlogPostCard";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../../consts/GeneralConst";
import logoBlack from "../../images/logos/logoBlack.png";
import BlogService from "../../services/BlogService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./Blog.scss";

const TITLE = `Blog | ${GeneralConst.SITE_NAME}`;

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const blogStrings = GeneralStrings.BLOG;

  useEffect(() => {
    BlogService.getAllBlogPosts().then((response) => {
      const blogPosts = response?.data;
      setPosts(blogPosts);
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="blog">
        <div className="blog section section-our-clients-freebie">
          <div className="container">
            <div className="title-area">
              <h2>{blogStrings.BLOG}</h2>
              <div className="separator">
                <img src={logoBlack} className="seperator-img" alt="logo" />
              </div>
            </div>
            <div className="blog-post-container">
              {!_.isEmpty(posts) &&
                posts.map((post) => (
                  <div className="col-sm-12 col-md-4" key={"post-" + post.id}>
                    <BlogPostCard post={post} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Blog;
