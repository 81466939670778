import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ProductsCountCard.scss";

const ProductsCountCard = () => {
  const [productsCount, setProductsCount] = useState(0);

  /* useEffect(() => {
  }, []); */

  return (
    <Paper elevation={0} className="products-count-card">
      <Card>
        <CardContent className="card-content">
          <Grid xs={12}>
            <div className="flex content-container">
              <LocalOfferIcon
                sx={{ color: "cornflowerblue", fontSize: "50px" }}
              />
              <div className="margin-left-auto padding-top-5">
                <h2>{productsCount}</h2>
                <p className="card-description">
                  {GeneralStrings.DASHBOARD.TOTAL_PRODUCTS_COUNT}
                </p>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ProductsCountCard;
