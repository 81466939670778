import React, { useState } from "react";
import logoWhite from "../../images/logos/logoWhite.png";
import GeneralStrings from "../../strings/GeneralStrings.json";
import {
  getMostRecentVideo,
  getYoutubeStatistics,
} from "../../utilities/YoutubeHelper";
import "./YoutubeChannel.scss";

const YoutubeChannel = () => {
  const [subscribers, setSubscribers] = useState(0);
  const [views, setViews] = useState(0);
  const [videoSrc, setVideoSrc] = useState(null);
  const youtubeStrings = GeneralStrings.YOUTUBE;

  getYoutubeStatistics(setViews, setSubscribers);
  getMostRecentVideo(setVideoSrc);

  return (
    <div className="section section-black youtube-background">
      <div className="container">
        <div className="youtube-title-area">
          <h2>{youtubeStrings.TITLE}</h2>
          <div className="separator">
            <img src={logoWhite} className="seperator-img" alt="logo" />
          </div>
        </div>

        <div className="container youtube-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 youtube-stats-section">
              <div id="views" className="row youtube-stats">
                <div className="col-md-2 col-xs-12">
                  <i className="fas fa-eye fa-5x" />
                </div>
                <div className="col-md-10 col-xs-12">
                  <div id="viewsOdometer" className="odometer">
                    {views}
                  </div>
                  <div className="odometer-text">
                    {youtubeStrings.CHANNEL_VIEWS}
                  </div>
                </div>
              </div>

              <br />

              <div id="subs" className="row youtube-stats">
                <div className="col-md-2">
                  <i className="fas fa-users fa-5x" />
                </div>
                <div className="col-md-10">
                  <div id="subsOdometer" className="odometer">
                    {subscribers}
                  </div>
                  <div className="odometer-text">
                    {youtubeStrings.SUBSCRIBERS}
                  </div>
                </div>
              </div>

              <br />

              <button type="button" className="btn btn-block btn-youtube">
                <i className="fab fa-youtube left" /> {youtubeStrings.CTA}
              </button>
            </div>

            <div className="col-md-6 col-sm-12">
              <iframe
                className="responsive-iframe"
                title="Beat Video"
                src={videoSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubeChannel;
