import React from "react";

const BeatStarsMarketplace = ({
  scrolling,
  width,
  height,
  frameBorder,
  marginHeight,
  marginWidth,
}) => {
  return (
    <iframe
      src="https://player.beatstars.com/?storeId=55549"
      title="BeatStars Marketplace"
      scrolling={scrolling}
      width={width}
      height={height}
      frameBorder={frameBorder}
      marginHeight={marginHeight}
      marginWidth={marginWidth}
      className="playerBorder"
    >
      -- none --
    </iframe>
  );
};

export default BeatStarsMarketplace;
