import Button from "@mui/joy/Button";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ConfirmCancelModal.scss";

const ConfirmCancelModal = ({
  cancelMessage,
  handleCancel,
  isOpen,
  toggle,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="confirm-delete-modal"
    >
      <ModalHeader toggle={toggle} className="margin-bottom-20">
        {GeneralStrings.FORM.CONFIRM_CANCEL}
      </ModalHeader>
      <ModalBody>
        <div className="padding-left-50 padding-right-50 margin-bottom-25">
          <h4 className="margin-bottom-40">
            {cancelMessage || GeneralStrings.FORM.CONFIRM_CANCEL_DESCRIPTION}
          </h4>
          <div className="flex flex-row">
            <Button
              type="submit"
              color="primary"
              className="text-lighter margin-left-auto"
              onClick={toggle}
            >
              {GeneralStrings.FORM.GO_BACK}
            </Button>
            <Button
              type="submit"
              color="danger"
              className="text-lighter margin-left-20"
              onClick={handleCancel}
            >
              {GeneralStrings.FORM.CANCEL}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmCancelModal;
