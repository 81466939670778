/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "@mui/joy";
import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import ArtistService from "../../../services/ArtistService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import AddArtistModal from "./AddArtistModal";
import "./ArtistsPage.scss";

const ArtistsPage = () => {
  const [artists, setArtists] = useState([]);
  const [progressBarData, setProgressBarData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [showAddArtistModal, setShowAddArtistModal] = useState(false);
  const [showDeleteArtistModal, setShowDeleteArtistModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const artistsStrings = GeneralStrings.DASHBOARD.ARTISTS;

  const toggleAddArtistModal = () => setShowAddArtistModal(!showAddArtistModal);
  const toggleDeleteArtistModal = () =>
    setShowDeleteArtistModal(!showDeleteArtistModal);

  const addArtist = (event) => {
    event.preventDefault();
    toggleAddArtistModal();
  };

  const deleteArtist = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    toggleDeleteArtistModal();
  };

  const handleDelete = (formData) => {
    const { deleteValue } = formData;

    ArtistService.deleteArtist(deleteValue)
      .then(() => {
        setSuccessMessage(AlertStrings.DELETE_ARTIST_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.DELETE_ARTIST_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        toggleDeleteArtistModal();
      });
  };

  useEffect(() => {
    ArtistService.getAllArtists()
      .then((response) => {
        const artistsData = response?.data;
        setArtists(artistsData);
        setProgressBarData({
          count: artistsData?.length,
          limit: 50,
          title: artistsStrings.PROGRESS_BAR_TITLE,
        });
      })
      .catch(() => {
        setFailedMessage(AlertStrings.GENERAL_ERROR);
        setShowToast(true);
      });
  }, [showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="artists-page">
        <DashboardSubPageToolbar
          addButtonText={artistsStrings.ADD_ARTIST}
          addFunction={addArtist}
          progressBarData={progressBarData}
          title={artistsStrings.ARTISTS}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              stickyHeader
              hoverRow
              stripe="odd"
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: 400 }}>{artistsStrings.NAME}</th>
                  <th>{artistsStrings.SPOTIFY_ID}</th>
                  <th style={{ width: 200 }}></th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(artists) &&
                  artists.map((artist) => (
                    <tr key={"artist-" + artist.name}>
                      <td>{artist.name}</td>
                      <td>{artist.spotifyID}</td>
                      <td className="flex flex-row">
                        <Button
                          className="dashboard-button margin-horizonal-center"
                          color="error"
                          variant="contained"
                          data-value={artist.id}
                          onClick={deleteArtist}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Paper>
        </Grid>
        <AddArtistModal
          isOpen={showAddArtistModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleAddArtistModal}
        />
        <ConfirmDeleteModal
          deleteMessage={artistsStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeleteArtistModal}
          deleteValue={deleteValue}
          toggle={toggleDeleteArtistModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default ArtistsPage;
