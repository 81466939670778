import Skeleton from "@mui/material/Skeleton";
import "./EditBlogPostSkeleton.scss";

const EditBlogPostSkeleton = () => {
  return (
    <div className="edit-blog-post-skeleton">
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginTop: "10px", marginBottom: "25px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginBottom: "25px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginBottom: "25px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={400}
        sx={{ marginBottom: "25px" }}
      />
    </div>
  );
};

export default EditBlogPostSkeleton;
