import { MobileView } from "react-device-detect";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import LicensesConst from "../../consts/LicensesConst";
import logoWhite from "../../images/logos/logoWhite.png";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./Pricing.scss";
import PricingCard from "./PricingCard.jsx";

const Pricing = () => {
  const pricingStrings = GeneralStrings.PRICING;

  return (
    <div className="pricing" id="pricing">
      <div className="section pricing-background text-white">
        <div className="pricing-container">
          <div className="content">
            <div className="row">
              <div className="title-area">
                <h2>{pricingStrings.TITLE}</h2>
                <div className="separator">
                  <img src={logoWhite} className="seperator-img" alt="logo" />
                </div>
                <p className="pricing-description">
                  {pricingStrings.DESCRIPTION}
                </p>
                <MobileView>
                  {GeneralStrings.MOBILE_PRICING_DESCRIPTION}
                </MobileView>
              </div>
            </div>

            <div className="row" id="tables">
              <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={"auto"}
                pagination={{ clickable: true }}
                className="padding-bottom-50"
              >
                {LicensesConst.LICENSES.map((license) => (
                  <SwiperSlide key={"slide-" + license.id}>
                    <PricingCard license={license} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <p
              className="bulk-deals-description margin-top-50 margin-bottom-25 text-center"
              dangerouslySetInnerHTML={{
                __html: pricingStrings.BULK_DEALS_HTML,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
