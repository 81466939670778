/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class UserService {
  /**
   * [POST]
   * Adds user to database
   * @param {String} email
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} position
   * @param {String} password
   * @returns {Promise}
   */
  addUser({ email, firstName, lastName, position, password }) {
    return axios({
      method: "post",
      url: getAPIUrl() + EndpointsConst.API.USERS.ADD_USER,
      headers: {},
      data: { email, firstName, lastName, position, password },
    });
  }

  /**
   * [POST]
   * Changes Password for User
   * @param {String} email
   * @param {String} currentPassword
   * @param {String} newPassword
   * @returns {Promise}
   */
  changePassword(email, currentPassword, newPassword) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.CHANGE_PASSWORD.replace("{email}", email)
        .replace("{currentPassword}", currentPassword)
        .replace("{newPassword}", newPassword);
    return axios.post(url);
  }

  /**
   * [DELETE]
   * Deletes user by email
   * @param {String} email
   * @returns {Promise}
   */
  deleteUser(email) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.DELETE_USER.replace("{email}", email);
    return axios.delete(url);
  }

  /**
   * [POST]
   * Logs user in
   * @param {String} email
   * @returns {Promise}
   */
  generateTemporaryPassword({ email }) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.GENERATE_TEMPORARY_PASSWORD.replace(
        "{email}",
        email
      );
    return axios.post(url);
  }

  /**
   * [GET]
   * Retrieves all users
   * @returns {Promise}
   */
  getAllUsers() {
    let url = getAPIUrl() + EndpointsConst.API.USERS.GET_ALL_USERS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves total user count
   * @returns {Promise}
   */
  getTotalUserCount() {
    let url = getAPIUrl() + EndpointsConst.API.USERS.GET_TOTAL_USER_COUNT;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves user by email
   * @returns {Promise}
   */
  getUserByEmail(email) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.GET_USER_BY_EMAIL.replace("{email}", email);
    return axios.get(url);
  }

  /**
   * [POST]
   * Attempts to Logs user in, will respond based on account status
   * @param {String} email
   * @param {String} password
   * @returns {Promise}
   */
  login({ email, password }) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.LOGIN.replace("{email}", email).replace(
        "{password}",
        password
      );
    return axios.post(url);
  }

  /**
   * [POST]
   * Resets Password for User
   * @param {String} email
   * @param {String} password
   * @returns {Promise}
   */
  resetPassword(email, password) {
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.RESET_PASSWORD.replace("{email}", email).replace(
        "{password}",
        password
      );
    return axios.post(url);
  }

  /**
   * [POST]
   * Updates the user's profile
   * @param {Object} email
   * @returns {Promise}
   */
  updateUserProfile(formValues) {
    console.log(formValues);
    let url =
      getAPIUrl() +
      EndpointsConst.API.USERS.UPDATE_USER_PROFILE.replace(
        "{email}",
        formValues.email
      )
        .replace("{firstName}", formValues.firstName)
        .replace("{lastName}", formValues.lastName)
        .replace("{position}", formValues.position)
        .replace(
          "{profilePic}",
          formValues.profilePicture || formValues.currentProfilePic
        );
    return axios.post(url);
  }
}

export default new UserService();
