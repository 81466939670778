import { SwipeableDrawer } from "@mui/material";
import { Link } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import GeneralStrings from "../../strings/GeneralStrings";
import "./MobileNavDrawer.scss";

const MobileNavDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const navBarStrings = GeneralStrings.NAVBAR;

  return (
    <SwipeableDrawer
      anchor="left"
      className="mobile-nav-drawer"
      open={isDrawerOpen}
      onOpen={() => {}}
      onClose={() => setIsDrawerOpen(false)}
    >
      <div className="mobile-nav-link-container flex-col">
        <Link
          to={RoutesConst.DEFAULT}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.HOME}
        </Link>
        <Link
          to={RoutesConst.BLOG.DEFAULT}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.BLOG}
        </Link>
        <Link
          to={RoutesConst.PROJECTS}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.PROJECTS}
        </Link>
        <Link
          to={RoutesConst.FAQ}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.FAQ}
        </Link>
        <hr className="social-divider" />
        <div className="mobile-nav-social-links-container flex">
          <Link
            href="https://www.youtube.com/channel/UCqiRdTiMJ1jWPeVELNkkQ9Q?view_as=subscriber?sub_confirmation=1"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-youtube social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.tiktok.com/@beatsbyzer0"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-tiktok social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.twitch.tv/beatsbyzer0"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-twitch social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://open.spotify.com/artist/34dd7Gv3nr4D0JqhJznlSg"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-spotify social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://music.apple.com/us/artist/zer0-tolerance/1581294675"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-apple social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://audius.co/beatsbyzer0"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-solid fa-music social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.twitter.com/beatsbyzer0"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-twitter social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.instagram.com/beatsbyzer0/"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-instagram social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.facebook.com/beatsbyzer0/"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-facebook-square social-icon fa-lg"></i>
          </Link>
          <Link
            href="mailto:ztinstrumentals@gmail.com"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-regular fa-envelope social-icon fa-lg"></i>
          </Link>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileNavDrawer;
