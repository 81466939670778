/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class BlogService {
  /**
   * [POST]
   * Submits the new blog post to the database
   * @param {String} date
   * @param {String} title
   * @param {String} subtitle
   * @param {String} imgLoc
   * @param {String} content
   * @returns {Promise}
   */
  addBlogPost({ date, title, subtitle, imgLoc, content }) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.BLOG.ADD_BLOG_POST,
      headers: {},
      data: { date, title, subtitle, imgLoc, content },
    });
  }

  /**
   * [DELETE]
   * Deletes Blog Post from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteBlogPost(id) {
    return axios({
      method: "DELETE",
      url:
        getAPIUrl() +
        EndpointsConst.API.BLOG.DELETE_BLOG_POST.replace("{id}", id),
      headers: {},
    });
  }

  /**
   * [POST]
   * Edits the blog post
   * @param {int} id
   * @param {String} date
   * @param {String} title
   * @param {String} subtitle
   * @param {String} imgLoc
   * @param {String} content
   * @returns {Promise}
   */
  editBlogPost({ id, date, title, subtitle, imgLoc, content }) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.BLOG.EDIT_BLOG_POST,
      headers: {},
      data: { id, date, title, subtitle, imgLoc, content },
    });
  }

  /**
   * [GET]
   * Gets all blog posts
   * @returns {Promise}
   */
  getAllBlogPosts() {
    return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.BLOG.GET_ALL_BLOG_POSTS,
      headers: {},
    });
  }

  /**
   * [GET]
   * Gets blog post by ID
   * @param {Number} id
   * @returns {Promise}
   */
  getBlogPostById(id) {
    return axios({
      method: "GET",
      url:
        getAPIUrl() +
        EndpointsConst.API.BLOG.GET_BLOG_POST_BY_ID.replace("{id}", id),
      headers: {},
    });
  }
}

export default new BlogService();
