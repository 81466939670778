/* eslint-disable import/no-anonymous-default-export */
export default {
  BLOG: {
    DEFAULT: "/blog",
    VIEW_POST: "/blog/view-post",
  },
  DEFAULT: "/",
  DASHBOARD: {
    ACCOUNT_SETTINGS: "/dashboard/account-settings",
    ARTISTS: "/dashboard/artists",
    BLOG: {
      ADD_POST: "/dashboard/blog/add-post",
      DEFAULT: "/dashboard/blog",
      EDIT_POST: "/dashboard/blog/edit-post",
    },
    DEFAULT: "/dashboard",
    FAQ: "/dashboard/faq",
    HELP: "/dashboard/help",
    USERS: "/dashboard/users",
  },
  FAQ: "/faq",
  LOGIN: "/login",
  PROJECTS: "/projects",
};
