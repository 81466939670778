/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class FAQService {
  /**
   * [POST]
   * Submits a new faq to the database
   * @param {String} question
   * @param {String} answer
   * @returns {Promise}
   */
  addFAQ({ question, answer }) {
    let url = getAPIUrl() + EndpointsConst.API.FAQ.ADD_FAQ;
    return axios.post(url, { question, answer });
  }

  /**
   * [DELETE]
   * Deletes FAQ from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteFAQ(id) {
    let url =
      getAPIUrl() + EndpointsConst.API.FAQ.DELETE_FAQ.replace("{id}", id);
    return axios.delete(url);
  }

  /**
   * [GET]
   * Retrieves all FAQs from database
   * @returns {Promise}
   */
  getAllFAQs() {
    let url = getAPIUrl() + EndpointsConst.API.FAQ.GET_ALL_FAQS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves FAQ by Id from database
   * @param {Number} id
   * @returns {Promise}
   */
  getFAQById(id) {
    let url =
      getAPIUrl() + EndpointsConst.API.FAQ.GET_FAQ_BY_ID.replace("{id}", id);
    return axios.get(url);
  }
}

export default new FAQService();
