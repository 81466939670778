import React from "react";
import logoBlack from "../../images/logos/logoBlack.png";
import downloadImg from "../../images/misc/download-arrow.png";
import GeneralStrings from "../../strings/GeneralStrings.json";
import BeatStarsMarketplace from "./BeatStarsMarketplace.jsx";
import "./BeatStore.scss";

const BeatStore = () => {
  const beatStoreStrings = GeneralStrings.BEAT_STORE;

  return (
    <div className="section" id="store">
      <div className="container">
        <div className="row">
          <div className="title-area">
            <h2>{beatStoreStrings.TITLE}</h2>
            <div className="separator">
              <img src={logoBlack} className="seperator-img" alt="Logo" />
            </div>
            <p
              className="beat-store-description"
              dangerouslySetInnerHTML={{
                __html: beatStoreStrings.DESCRIPTION_HTML.replace(
                  "{imgLoc}",
                  downloadImg
                ),
              }}
            />
          </div>
        </div>

        <div className="row marketplace-iframe">
          <BeatStarsMarketplace
            scrolling="no"
            width="100%"
            height="750"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          />
        </div>
        <br />
        {/*<div className="row">
            <div className="mx-auto">
              <Link to="/elements-beat-pack" type="button" className="btn btn-deal">HOW ABOUT 10 FREE BEATS?</Link>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default BeatStore;
