import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../../consts/GeneralConst";
import logoBlack from "../../images/logos/logoBlack.png";
import FAQService from "../../services/FAQService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./FAQs.scss";

const TITLE = `FAQ | ${GeneralConst.SITE_NAME}`;

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  const faqStrings = GeneralStrings.FAQ;

  useEffect(() => {
    FAQService.getAllFAQs().then((response) => {
      const faqData = response?.data;
      setFaqs(faqData);
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="faqs">
        <div className="faqs section section-our-clients-freebie">
          <div className="container">
            <div className="title-area">
              <h2>{faqStrings.FREQUENTLY_ASKED_QUESTIONS}</h2>
              <div className="separator">
                <img src={logoBlack} className="seperator-img" alt="logo" />
              </div>
            </div>
            <div className="faq-group">
              {!_.isEmpty(faqs) &&
                faqs.map((faq) => (
                  <div className="faq-item" key={"faq-" + faq.id}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h3>{faq.question}</h3>
                      </AccordionSummary>
                      <AccordionDetails className="faq-answer-container">
                        <p className="faq-answer">{faq.answer}</p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default FAQs;
