const videos = [
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/blackwhite1.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/blue.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/blue_and_red-B.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/cool_red_and_purple_delayed_swirl.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/gray_on_black_B.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/green_cyan_and_blue.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/iridescent_dark_blue_delayed_dark_blue.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/iridescent_royal_blue_delayed_swirl.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/light_blue_delayed_dark_blue.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/pink_and_purple_delayed_red_and_blue.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/purple.mp4',
    'https://beatsbyzero.s3.us-east-2.amazonaws.com/videos/white_and_purple_delayed_purple.mp4',
];

export default videos;