import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Spinner } from "reactstrap";
import ConfirmCancelModal from "../../../../components/ConfirmCancelModal/ConfirmCancelModal";
import DashboardSubPageToolbar from "../../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import RoutesConst from "../../../../consts/RoutesConst";
import AWSService from "../../../../services/AWSService";
import BlogService from "../../../../services/BlogService";
import AlertStrings from "../../../../strings/AlertStrings.json";
import GeneralStrings from "../../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../../utilities/ValidationHelper";
import DashboardLayout from "../../DashboardLayout/DashboardLayout";
import EditBlogPostSkeleton from "../EditBlogPostSkeleton/EditBlogPostSkeleton";
import "./EditBlogPost.scss";

const EditBlogPost = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const [blogPostData, setBlogPostData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [failedMessage, setFailedMessage] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const blogStrings = GeneralStrings.DASHBOARD.BLOG;

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleCancel = () => {
    navigate(RoutesConst.DASHBOARD.BLOG.DEFAULT);
  };

  const handleSubmit = (formValues) => {
    setIsSubmitting(true);

    if (file !== null) {
      const fileNameSplit = formValues.currentImgLoc.split("/");
      const currentFileName = fileNameSplit.pop();

      AWSService.deleteBlogImage(currentFileName)
        .then(() => {
          AWSService.uploadBlogImage(file)
            .then((res) => {
              const imgLoc = res.Location;
              const params = { imgLoc, ...formValues };

              BlogService.editBlogPost(params)
                .then(() => {
                  navigate({
                    pathname: RoutesConst.DASHBOARD.BLOG.DEFAULT,
                  });
                })
                .catch(() => {
                  setFailedMessage(AlertStrings.UPDATE_BLOG_POST_FAIL);
                  setShowToast(true);
                  setIsSubmitting(false);
                });
            })
            .catch(() => {
              setFailedMessage(AlertStrings.UPLOAD_BLOG_IMAGE_FAIL);
              setShowToast(true);
              setIsSubmitting(false);
            });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.DELETE_BLOG_IMAGE_FAIL);
          setShowToast(true);
          setIsSubmitting(false);
        });
    } else {
      BlogService.editBlogPost(formValues)
        .then(() => {
          navigate({
            pathname: RoutesConst.DASHBOARD.BLOG.DEFAULT,
            search: createSearchParams({
              successfulPostEdit: true,
            }).toString(),
          });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.UPDATE_BLOG_POST_FAIL);
          setShowToast(true);
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (id === null) {
      setFailedMessage(AlertStrings.GET_BLOG_POST_DATA_ERROR);
      setShowToast(true);
    } else {
      BlogService.getBlogPostById(id)
        .then((response) => {
          setBlogPostData(response?.data);
        })
        .catch(() => {
          setFailedMessage(AlertStrings.GET_BLOG_POST_DATA_ERROR);
          setShowToast(true);
        })
        .finally(() => {
          setIsLoading(false);
          setShowToast(false);
        });
    }
  }, [showToast, searchParams]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="edit-blog-post">
        <DashboardSubPageToolbar title={blogStrings.EDIT_POST} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            {isLoading ? (
              <EditBlogPostSkeleton />
            ) : (
              <Formik
                initialValues={{
                  id: blogPostData?.id,
                  title: blogPostData?.title,
                  subtitle: blogPostData?.subtitle,
                  currentImgLoc: blogPostData?.imgLoc,
                  content: blogPostData?.content,
                }}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ handleChange, values }) => (
                  <Form className="margin-top-15 margin-bottom-15">
                    <FormControl className="margin-bottom-35" required>
                      <FormLabel>{GeneralStrings.FORM.TITLE}</FormLabel>
                      <Field
                        name="title"
                        className="form-control"
                        validate={validateTextField}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="title"
                      />
                    </FormControl>
                    <FormControl className="margin-bottom-35" required>
                      <FormLabel>{GeneralStrings.FORM.SUBTITLE}</FormLabel>
                      <Field
                        name="subtitle"
                        className="form-control"
                        validate={validateTextField}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="subtitle"
                      />
                    </FormControl>
                    <FormControl className="margin-bottom-35" required>
                      <FormLabel>{GeneralStrings.FORM.IMAGE}</FormLabel>
                      <Field
                        name="image"
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                        className="form-control"
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="image"
                      />
                      <sub
                        className="margin-top-15"
                        dangerouslySetInnerHTML={{
                          __html: blogStrings.CURRENT_IMAGE_HTML.replace(
                            "{imgLoc}",
                            blogPostData.imgLoc
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl className="margin-bottom-35" required>
                      <FormLabel>{GeneralStrings.FORM.CONTENT}</FormLabel>
                      <Field name="content">
                        {({ field }) => (
                          <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            initialValue={
                              blogPostData?.content ||
                              "<p>Enter blog post here...</p>"
                            }
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "advlist",
                                "autolink",
                                "link",
                                "lists",
                                "charmap",
                                "preview",
                                "anchor",
                                "pagebreak",
                                "searchreplace",
                                "wordcount",
                                "visualblocks",
                                "visualchars",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "emoticons",
                                "help",
                              ],
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                            onEditorChange={(e) => {
                              handleChange({
                                target: { name: "content", value: e },
                              });
                            }}
                            textareaName="content"
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={values.content}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <div className="flex flex-row">
                      <Button
                        color="primary"
                        className="text-lighter margin-left-auto margin-bottom-25"
                        onClick={toggleCancelModal}
                      >
                        {GeneralStrings.FORM.CANCEL}
                      </Button>
                      <Button
                        type="submit"
                        color="success"
                        disabled={isSubmitting}
                        className="text-lighter margin-left-25 margin-bottom-25"
                      >
                        {GeneralStrings.FORM.SUBMIT}
                        {isSubmitting && (
                          <Spinner
                            color="dark"
                            size="sm"
                            className="margin-left-10"
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Paper>
        </Grid>
        <ConfirmCancelModal
          cancelMessage={blogStrings.CANCEL_DESCRIPTION}
          handleCancel={handleCancel}
          isOpen={showCancelModal}
          toggle={toggleCancelModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default EditBlogPost;
