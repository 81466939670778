import YouTubeIcon from "@mui/icons-material/YouTube";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getYoutubeSubscriberCount } from "../../utilities/YoutubeHelper";
import "./SubscriberCountCard.scss";

const SubscriberCountCard = () => {
  const [subscriberCount, setSubscriberCount] = useState(0);

  useEffect(() => {
    getYoutubeSubscriberCount(setSubscriberCount);
  }, []);

  return (
    <Paper elevation={0} className="subscriber-count-card">
      <Card>
        <CardContent className="card-content">
          <Grid xs={12}>
            <div className="flex content-container">
              <YouTubeIcon sx={{ color: "red", fontSize: "50px" }} />
              <div className="margin-left-auto padding-top-5">
                <h2>{subscriberCount}</h2>
                <p className="card-description">
                  {GeneralStrings.DASHBOARD.YOUTUBE_SUBSCRIBER_COUNT}
                </p>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SubscriberCountCard;
