import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { addCommas } from "./FormatHelper";

const YOUTUBE_CHANNEL = "UCqiRdTiMJ1jWPeVELNkkQ9Q";
const DEFAULT_YOUTUBE_VIDEO =
  "https://www.youtube.com/embed/tAV9VbHjmVg?si=bgh3hFIBi2e40MP6";
const SINGLE_VIDEO = 1;

export function getYoutubeStatistics(setViews, setSubscribers) {
  let url = EndpointsConst.YOUTUBE_STATISTICS_ENDPOINT.replace(
    "{channelCode}",
    YOUTUBE_CHANNEL
  ).replace("{apiKey}", process.env.REACT_APP_YOUTUBE_API_KEY);
  axios.get(url).then((res) => {
    const views = addCommas(res?.data?.items[0].statistics.viewCount);
    const subscribers = addCommas(
      res?.data?.items[0].statistics.subscriberCount
    );
    setViews(views);
    setSubscribers(subscribers);
  });
}

export function getYoutubeSubscriberCount(setSubscribers) {
  let url = EndpointsConst.YOUTUBE_STATISTICS_ENDPOINT.replace(
    "{channelCode}",
    YOUTUBE_CHANNEL
  ).replace("{apiKey}", process.env.REACT_APP_YOUTUBE_API_KEY);

  axios.get(url).then((res) => {
    const subscribers = addCommas(
      res?.data?.items[0].statistics.subscriberCount
    );
    setSubscribers(subscribers);
  });
}

export function getMostRecentVideo(setVideoSrc) {
  let url = EndpointsConst.YOUTUBE_STATISTICS_ENDPOINT.replace(
    "{resultAmount}",
    SINGLE_VIDEO
  ).replace("{apiKey}", process.env.REACT_APP_YOUTUBE_API_KEY);
  axios.get(url).then((res) => {
    // TODO: Needs to be correctly parsed, my be wrong endpoint/endpoint parameters
    setVideoSrc(res.data.items ? res.data.items[0] : DEFAULT_YOUTUBE_VIDEO);
  });
}
