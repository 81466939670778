/* eslint-disable import/no-anonymous-default-export */
export default {
  DOMAIN_NAME: "beatsbyzero.com",
  LOGIN_FORM_TYPE: {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    LOGIN: "LOGIN",
    RESET_PASSWORD: "RESET_PASSWORD",
  },
  SITE_NAME: "Beats By Zer0",
  STATUS_CODES: {
    DATA_ERROR: "DATA_ERROR",
    ERROR: "ERROR",
    PARTIAL_SUCCESS: "PARTIAL_SUCCESS",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
  },
};
