import { Button } from "@mui/material";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./LicenseModal.scss";

const LicenseModal = (props) => {
  const { buttonClassName, description, title, notice } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const htmlToParse = { __html: description };

  return (
    <div>
      <Button className={buttonClassName} onClick={toggle}>
        {title}
      </Button>
      <Modal isOpen={modal} size="lg" toggle={toggle} className="license-modal">
        <ModalHeader toggle={toggle} className="license-modal-bg">
          {title}
        </ModalHeader>
        <ModalBody className="license-modal-bg">
          <p className="license-modal-notice">{notice}</p>
          <hr />
          <p dangerouslySetInnerHTML={htmlToParse} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LicenseModal;
