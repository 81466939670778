import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import BlogService from "../../services/BlogService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ViewBlogPost.scss";

const TITLE_TAG = "| Beats By Zer0";

const ViewBlogPost = () => {
  const [post, setPost] = useState({});
  const [searchParams] = useSearchParams();
  const blogStrings = GeneralStrings.BLOG;

  useEffect(() => {
    const id = searchParams.get("id");
    BlogService.getBlogPostById(id).then((response) => {
      setPost(response?.data);
    });
  }, [searchParams]);

  return (
    <Fragment>
      <Helmet>
        <title>{`${post?.title} ${TITLE_TAG}`}</title>
      </Helmet>
      <HomepageNavbar />
      <div className="view-blog-post">
        <header
          className="masthead"
          style={{
            backgroundImage: `url(${post?.imgLoc})`,
          }}
        >
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-7">
                <div className="post-heading">
                  <h1>{post?.title}</h1>
                  <h2 className="subheading">{post?.subtitle}</h2>
                  <span className="meta">
                    <AccessTimeIcon />
                    <span className="date">
                      {blogStrings.POSTED_ON.replace("{date}", post?.date)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <article className="article-container">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div
                className="col-md-10 col-lg-8 col-xl-7"
                dangerouslySetInnerHTML={{ __html: post?.content }}
              />
            </div>
          </div>
        </article>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ViewBlogPost;
