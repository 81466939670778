import _ from "lodash";

const _getToken = async () => {
  const result = await fetch("https://accounts.spotify.com/api/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " +
        btoa(
          process.env.REACT_APP_SPOTIFY_CLIENT_ID +
            ":" +
            process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
        ),
    },
    body: "grant_type=client_credentials",
  });

  const data = await result.json();
  return data.access_token;
};

export async function getArtists(artistIDs) {
  const token = await _getToken();

  const result = await fetch(
    `https://api.spotify.com/v1/artists?ids=${_.join(artistIDs)}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await result.json();
  return data.artists;
}

export async function getUserData() {
  const token = await _getToken();

  const result = await fetch(
    `https://api.spotify.com/v1/artists/${process.env.REACT_APP_SPOTIFY_ID}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await result.json();
  return data;
}

export async function getUserAlbums() {
  const token = await _getToken();

  const result = await fetch(
    `https://api.spotify.com/v1/artists/${process.env.REACT_APP_SPOTIFY_ID}/albums`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await result.json();
  return data;
}
