import Grid from "@mui/material/Grid";
import ArtistsCountCard from "../../components/ArtistsCountCard/ArtistsCountCard";
import ArtistsDashboardCard from "../../components/ArtistsDashboardCard/ArtistsDashboardCard";
import BlogDashboardCard from "../../components/BlogDashboardCard/BlogDashboardCard";
import ProductsCountCard from "../../components/ProductsCountCard/ProductsCountCard";
import ServicesDashboardCard from "../../components/ServicesDashboardCard/ServicesDashboardCard";
import SiteViewCountCard from "../../components/SiteViewCountCard/SiteViewCountCard";
import SoundKitsDashboardCard from "../../components/SoundKitsDashboardCard/SoundKitsDashboardCard";
import SpotifyFollowerCountCard from "../../components/SpotifyFollowerCountCard/SpotifyFollowerCountCard";
import SubscriberCountCard from "../../components/SubscriberCountCard/SubscriberCountCard";
import UserCountCard from "../../components/UserCountCard/UserCountCard";
import UsersDashboardCard from "../../components/UsersDashboardCard/UsersDashboardCard";
import DashboardLayout from "./DashboardLayout/DashboardLayout";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <SiteViewCountCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <UserCountCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SubscriberCountCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <ArtistsCountCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <SpotifyFollowerCountCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductsCountCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <BlogDashboardCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <ArtistsDashboardCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <SoundKitsDashboardCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ServicesDashboardCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <UsersDashboardCard />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
