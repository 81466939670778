import Avatar from "@mui/joy/Avatar";
import { Link } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import logoBlack from "../../images/logos/logoBlack.png";
import ArtistService from "../../services/ArtistService";
import GeneralStrings from "../../strings/GeneralStrings";
import { getArtists } from "../../utilities/SpotifyHelper";
import "./Artists.scss";

const Artists = () => {
  const [artists, setArtists] = useState([]);
  const artistsStrings = GeneralStrings.ARTISTS;

  useEffect(() => {
    ArtistService.getAllArtistsSpotifyIds().then((res) => {
      getArtists(res.data).then((res) => {
        setArtists(res);
      });
    });
  }, []);

  return (
    <div className="section artists" id="artists">
      <div className="container">
        <div className="title-area">
          <h2>{artistsStrings.TITLE}</h2>
          <div className="separator">
            <img src={logoBlack} className="seperator-img" alt="logo" />
          </div>
          <p className="artist-description">{artistsStrings.DESCRIPTION}</p>
        </div>
      </div>

      <div className="artist-slide-container">
        <div className="slider-container">
          <div className="artists-slide">
            {!_.isEmpty(artists) &&
              artists.map((artist) => (
                <div className="artist" key={"set-1-" + artist?.name}>
                  <Link
                    href={artist?.external_urls?.spotify}
                    underline="none"
                    className="flex flex-col"
                    target="_blank"
                  >
                    <Avatar
                      className="artist-img"
                      color="neutral"
                      size="lg"
                      src={artist?.images[0]?.url}
                      variant="soft"
                    />
                    <span className="artist-name text-center">
                      {artist?.name}
                    </span>
                  </Link>
                </div>
              ))}
          </div>
          <div className="artists-slide">
            {!_.isEmpty(artists) &&
              artists.map((artist) => (
                <div className="artist" key={"set-2-" + artist?.name}>
                  <Link
                    href={artist?.external_urls?.spotify}
                    underline="none"
                    className="flex flex-col"
                    target="_blank"
                  >
                    <Avatar
                      className="artist-img"
                      color="neutral"
                      size="lg"
                      src={artist?.images[0]?.url}
                      variant="soft"
                    />
                    <span className="artist-name text-center">
                      {artist?.name}
                    </span>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artists;
