import { AppBar, IconButton } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import logoBlack from "../../images/logos/logoBlack.png";
import logoWhite from "../../images/logos/logoWhite.png";
import GeneralStrings from "../../strings/GeneralStrings";
import classNames from "../../utilities/classNames";
import "./HomepageNavbar.scss";
import MobileNavDrawer from "./MobileNavDrawer";

const HomepageNavbar = ({ transparentNavbar = true }) => {
  const [isTransparent, setIsTransparent] = useState(transparentNavbar);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navBarStrings = GeneralStrings.NAVBAR;
  const logoText = GeneralStrings.BEATS_BY_ZER0;

  const handleTransparency = () => {
    if (window.scrollY >= 500) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };

  transparentNavbar && window.addEventListener("scroll", handleTransparency);

  return (
    <AppBar {...classNames("homepage-navbar", !isTransparent && "solid-nav")}>
      <div className="flex">
        <Link to={RoutesConst.LOGIN} className="logo-link">
          <img
            src={!isTransparent ? logoBlack : logoWhite}
            alt="Logo"
            className="navbar-logo"
          />
          <span className="fs-22 logo-text">{logoText}</span>
        </Link>
        <div className="nav-link-container flex">
          <Link to="/" className="nav-link">
            {navBarStrings.HOME}
          </Link>
          <Link to={RoutesConst.BLOG.DEFAULT} className="nav-link">
            {navBarStrings.BLOG}
          </Link>
          <Link to={RoutesConst.PROJECTS} className="nav-link">
            {navBarStrings.PROJECTS}
          </Link>
          <Link to={RoutesConst.FAQ} className="nav-link">
            {navBarStrings.FAQ}
          </Link>
          <div className="nav-social-links-container">
            <Link
              to="https://www.youtube.com/channel/UCqiRdTiMJ1jWPeVELNkkQ9Q?view_as=subscriber?sub_confirmation=1"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-youtube social-icon"></i>
            </Link>
            <Link
              to="https://www.tiktok.com/@beatsbyzer0"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-tiktok social-icon fa-sm"></i>
            </Link>
            <Link
              to="https://www.twitch.tv/beatsbyzer0"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-twitch social-icon"></i>
            </Link>
            <Link
              to="https://open.spotify.com/artist/34dd7Gv3nr4D0JqhJznlSg"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-spotify social-icon"></i>
            </Link>
            <Link
              to="https://music.apple.com/us/artist/zer0-tolerance/1581294675"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-apple social-icon"></i>
            </Link>
            <Link
              to="https://audius.co/beatsbyzer0"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-solid fa-music social-icon"></i>
            </Link>
            <Link
              to="https://www.twitter.com/beatsbyzer0"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-twitter social-icon"></i>
            </Link>
            <Link
              to="https://www.instagram.com/beatsbyzer0/"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-instagram social-icon"></i>
            </Link>
            <Link
              to="https://www.facebook.com/beatsbyzer0/"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-facebook-square social-icon"></i>
            </Link>
            <Link
              to="mailto:ztinstrumentals@gmail.com"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-regular fa-envelope social-icon"></i>
            </Link>
          </div>
        </div>
        <div className="nav-drawer-toggle">
          <IconButton
            size="medium"
            className="nav-drawer-toggle-button"
            onClick={() => setIsDrawerOpen(true)}
          >
            <i className="fa-solid fa-bars"></i>
          </IconButton>
          <MobileNavDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </div>
      </div>
    </AppBar>
  );
};

export default HomepageNavbar;
