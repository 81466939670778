import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { addCommas } from "../../utilities/FormatHelper";
import { getUserData } from "../../utilities/SpotifyHelper";
import "./SpotifyFollowerCountCard.scss";

const SpotifyFollowerCountCard = () => {
  const [followerCount, setFollowerCount] = useState(0);

  useEffect(() => {
    getUserData().then((res) => {
      setFollowerCount(addCommas(res?.followers?.total) || "N/A");
    });
  }, []);

  return (
    <Paper elevation={0} className="spotify-follower-count-card">
      <Card>
        <CardContent className="card-content">
          <Grid xs={12}>
            <div className="flex content-container">
              <i className="fa-brands fa-spotify"></i>
              <div className="margin-left-auto padding-top-5">
                <h2>{followerCount}</h2>
                <p className="card-description">
                  {GeneralStrings.DASHBOARD.SPOTIFY_FOLLOWER_COUNT}
                </p>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SpotifyFollowerCountCard;
