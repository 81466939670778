import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ServicesDashboardCard.scss";

const ServicesDashboardCard = () => {
  const servicesStrings = GeneralStrings.DASHBOARD.SERVICES;

  useEffect(() => {}, []);

  return (
    <Paper elevation={0} className="sound-kits-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{servicesStrings.SERVICES}</h3>
          <div className="sound-kits-table-card-container"></div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ServicesDashboardCard;
