import { Table } from "@mui/joy";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { useEffect, useState } from "react";
import ArtistService from "../../services/ArtistService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ArtistsDashboardCard.scss";

const ArtistsDashboardCard = () => {
  const [artists, setArtists] = useState([]);
  const artistsStrings = GeneralStrings.DASHBOARD.ARTISTS;

  useEffect(() => {
    ArtistService.getAllArtists().then((response) => {
      const artistsData = response?.data;
      setArtists(artistsData);
    });
  }, []);

  return (
    <Paper elevation={0} className="artists-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{artistsStrings.ARTISTS}</h3>
          <div className="card-table-container">
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              hoverRow
              stripe="odd"
              stickyHeader
              variant="plain"
            >
              <thead>
                <tr>
                  <th>{artistsStrings.NAME}</th>
                  <th>{artistsStrings.SPOTIFY_ID}</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(artists) &&
                  artists.map((artist) => (
                    <tr key={"artist-" + artist.name}>
                      <td className="cell-container">{artist.name}</td>
                      <td className="cell-container">{artist.spotifyID}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ArtistsDashboardCard;
