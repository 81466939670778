import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import AccountPositions from "../../../consts/AccountPositionsConst.js";
import AWSService from "../../../services/AWSService";
import UserService from "../../../services/UserService";
import { selectUser } from "../../../slices/userSlice";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import "./AccountSettings.scss";
import AccountSettingsSkeleton from "./AccountSettingsSkeleton/AccountSettingsSkeleton";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";

const AccountSettings = () => {
  const user = useSelector(selectUser);
  const { email } = user;
  const [profilePic, setProfilePic] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const accountSettingsStrings = GeneralStrings.DASHBOARD.ACCOUNT_SETTINGS;

  const toggleChangePasswordModal = () =>
    setShowChangePasswordModal(!showChangePasswordModal);

  const handleProfilePicChange = (e) => {
    const pic = e.target.files[0];
    setProfilePic(pic);
  };

  const handleSubmit = (formValues) => {
    setIsSubmitting(true);

    const profileData = { email, ...formValues };
    const profilePicName = `${formValues.firstName}_${formValues.lastName}`;

    if (profilePic !== null) {
      if (
        (formValues.currentFileName !== null) |
        (formValues.currentFileName !== "")
      ) {
        const fileNameSplit = formValues.currentProfilePic.split("/");
        const currentFileName = fileNameSplit.pop();

        AWSService.deleteProfilePic(currentFileName).then(() => {
          AWSService.uploadProfilePic(profilePic, profilePicName).then(
            (res) => {
              const profilePicture = res.Location;
              const params = { profilePicture, ...profileData };

              UserService.updateUserProfile(params)
                .then(() => {
                  setSuccessMessage(AlertStrings.UPDATE_USER_PROFILE_SUCCESS);
                })
                .catch(() => {
                  setFailedMessage(AlertStrings.UPDATE_USER_PROFILE_ERROR);
                })
                .finally(() => {
                  setShowToast(true);
                  setIsSubmitting(false);
                });
            }
          );
        });
      } else {
        AWSService.uploadProfilePic(profilePic, profilePicName).then((res) => {
          const profilePicture = res.Location;
          const params = { profilePicture, ...profileData };

          UserService.updateUserProfile(params)
            .then(() => {
              setSuccessMessage(AlertStrings.UPDATE_USER_PROFILE_SUCCESS);
            })
            .catch(() => {
              setFailedMessage(AlertStrings.UPDATE_USER_PROFILE_ERROR);
            })
            .finally(() => {
              setShowToast(true);
              setIsSubmitting(false);
            });
        });
      }
    } else {
      UserService.updateUserProfile(profileData)
        .then(() => {
          setSuccessMessage(AlertStrings.UPDATE_USER_PROFILE_SUCCESS);
        })
        .catch(() => {
          setFailedMessage(AlertStrings.UPDATE_USER_PROFILE_ERROR);
        })
        .finally(() => {
          setShowToast(true);
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    UserService.getUserByEmail(email)
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.GET_USER_DATA_ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [email, showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="account-settings">
        <DashboardSubPageToolbar
          title={accountSettingsStrings.ACCOUNT_SETTINGS}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper elevation={0} className="account-settings-container">
            {isLoading ? (
              <AccountSettingsSkeleton />
            ) : (
              <Formik
                initialValues={{
                  firstName: userInfo?.firstName,
                  lastName: userInfo?.lastName,
                  position: userInfo?.position,
                  currentProfilePic: userInfo?.profilePic,
                }}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                <Form className="padding-left-50 padding-right-50 padding-top-25">
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.FIRST_NAME}</FormLabel>
                    <Field
                      name="firstName"
                      className="form-control"
                      validate={validateTextField}
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="firstName"
                    />
                  </FormControl>
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.LAST_NAME}</FormLabel>
                    <Field
                      name="lastName"
                      className="form-control"
                      validate={validateTextField}
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="lastName"
                    />
                  </FormControl>
                  <FormControl className="margin-bottom-35">
                    <FormLabel>{GeneralStrings.FORM.POSITION}</FormLabel>
                    <Field
                      as="select"
                      className="form-control select-control"
                      name="position"
                    >
                      <option></option>
                      {AccountPositions.map((position) => (
                        <option key={position} value={position}>
                          {position}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                  <FormControl className="margin-bottom-35">
                    <FormLabel>{GeneralStrings.FORM.PROFILE_PICTURE}</FormLabel>
                    <Avatar
                      alt="Profile Picture"
                      className="margin-top-15 margin-bottom-25"
                      src={userInfo?.profilePic}
                      sx={{ width: 150, height: 150 }}
                    />
                    <Field
                      name="profilePic"
                      accept="image/*"
                      type="file"
                      onChange={handleProfilePicChange}
                      className="form-control"
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="profilePic"
                    />
                  </FormControl>
                  <div className="flex flex-row">
                    <Button
                      color="primary"
                      className="text-lighter margin-left-auto margin-bottom-25"
                      onClick={toggleChangePasswordModal}
                    >
                      {GeneralStrings.FORM.CHANGE_PASSWORD}
                    </Button>
                    <Button
                      type="submit"
                      color="success"
                      disabled={isSubmitting}
                      className="text-lighter margin-left-25 margin-bottom-25"
                    >
                      {GeneralStrings.FORM.SAVE_CHANGES}
                      {isSubmitting && (
                        <Spinner
                          color="dark"
                          size="sm"
                          className="margin-left-10"
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              </Formik>
            )}
          </Paper>
        </Grid>
        <ChangePasswordModal
          isOpen={showChangePasswordModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleChangePasswordModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default AccountSettings;
