import React from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getCurrentYear } from "../../utilities/DateHelper.js";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="bg-black text-center site-footer">
      <hr />
      <div className="copyright">
        <span> © {getCurrentYear()} </span>
        <span
          dangerouslySetInnerHTML={{ __html: GeneralStrings.FOOTER_HTML }}
        />
      </div>
    </footer>
  );
};

export default Footer;
