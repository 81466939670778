/* eslint-disable import/no-anonymous-default-export */
var AWS = require("aws-sdk");
const S3_BUCKET = "beatsbyzero";
const REGION = "us-east-2";

class AWSService {
  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteBlogImage(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "blog/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteProfilePic(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "profile-pictures/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [PUT]
   * Uploads file into S3 bucket
   * @param {File} file
   * @returns {Promise}
   */
  uploadBlogImage(file) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "blog/" + file.name.replace(/ /g, "_"),
      Body: file,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads profile picture into S3 bucket
   * @param {File} profilePic
   * @param {String} profilePicName
   * @returns {Promise}
   */
  uploadProfilePic(profilePic, profilePicName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const extention = `.${profilePic.type.split("/").pop()}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `profile-pictures/${profilePicName}${extention}`,
      Body: profilePic,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }
}

export default new AWSService();
