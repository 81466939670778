import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./SoundKitsDashboardCard.scss";

const SoundKitsDashboardCard = () => {
  const soundKitsStrings = GeneralStrings.DASHBOARD.SOUND_KITS;

  useEffect(() => {}, []);

  return (
    <Paper elevation={0} className="sound-kits-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{soundKitsStrings.SOUND_KITS}</h3>
          <div className="sound-kits-table-card-container"></div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SoundKitsDashboardCard;
