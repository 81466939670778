import { BrowserView } from "react-device-detect";
import BackgroundVideos from "../../consts/BackgroundVideoConst";
import blackBackground from "../../images/backgrounds/black.jpg";
import logoWhite from "../../images/logos/logoWhite.png";
import "./TitleScreen.scss";

const TitleScreen = () => {
  const getBackgroundVideo = () => {
    const arry = BackgroundVideos;
    return arry[Math.floor(Math.random() * arry.length)];
  };
  const backgroundVideo = getBackgroundVideo();

  return (
    <div className="section section-header bg-black">
      <div className="parallax">
        <BrowserView>
          <video poster={blackBackground} autoPlay={true} loop muted>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </BrowserView>
        <div className="container-fluid">
          <div className="content">
            <div className="title-area">
              <img src={logoWhite} className="title-screen-logo" alt="logo" />
            </div>
            <span className="title-arrow">
              <i className="far fa-arrow-alt-circle-down fa-5x animated bounce"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleScreen;
