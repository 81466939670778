import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import BlogService from "../../services/BlogService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import BlogPostCard from "../BlogPostCard/BlogPostCard";
import "./BlogDashboardCard.scss";

const BlogDashboardCard = () => {
  const [posts, setPosts] = useState([]);
  const blogStrings = GeneralStrings.DASHBOARD.BLOG;

  useEffect(() => {
    BlogService.getAllBlogPosts().then((response) => {
      const blogPosts = response?.data;
      setPosts(blogPosts);
    });
  }, []);

  return (
    <Paper elevation={0} className="blog-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{blogStrings.BLOG}</h3>
          <div className="card-body-container">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={35}
              slidesPerView={"auto"}
            >
              {!_.isEmpty(posts) &&
                posts.map((post) => (
                  <SwiperSlide key={"post-" + post.id}>
                    <BlogPostCard post={post} showViewButton={false} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default BlogDashboardCard;
