import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import ConfirmCancelModal from "../../../../components/ConfirmCancelModal/ConfirmCancelModal";
import DashboardSubPageToolbar from "../../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import RoutesConst from "../../../../consts/RoutesConst";
import AWSService from "../../../../services/AWSService";
import BlogService from "../../../../services/BlogService";
import AlertStrings from "../../../../strings/AlertStrings.json";
import GeneralStrings from "../../../../strings/GeneralStrings.json";
import { getFullCurrentDate } from "../../../../utilities/DateHelper";
import { validateTextField } from "../../../../utilities/ValidationHelper";
import DashboardLayout from "../../DashboardLayout/DashboardLayout";
import "./AddBlogPost.scss";

const AddBlogPost = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const blogStrings = GeneralStrings.DASHBOARD.BLOG;

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleCancel = () => {
    navigate(RoutesConst.DASHBOARD.BLOG.DEFAULT);
  };

  const handleSubmit = (formValues) => {
    setIsSubmitting(true);
    AWSService.uploadBlogImage(file)
      .then((res) => {
        const date = getFullCurrentDate();
        const imgLoc = res.Location;
        const params = { imgLoc, date, ...formValues };

        BlogService.addBlogPost(params)
          .then(() => {
            navigate({
              pathname: RoutesConst.DASHBOARD.BLOG.DEFAULT,
            });
          })
          .catch(() => {
            setFailedMessage(AlertStrings.UPLOAD_BLOG_POST_FAIL);
            setShowToast(true);
            setIsSubmitting(false);
          });
      })
      .catch(() => {
        setFailedMessage(AlertStrings.UPLOAD_BLOG_IMAGE_FAIL);
        setShowToast(true);
        setIsSubmitting(false);
      });
  };

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="add-blog-post">
        <DashboardSubPageToolbar title={blogStrings.ADD_POST} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <Formik
              initialValues={{
                title: null,
                subtitle: null,
                content: null,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ handleChange, values }) => (
                <Form className="margin-top-15 margin-bottom-15">
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.TITLE}</FormLabel>
                    <Field
                      name="title"
                      className="form-control"
                      validate={validateTextField}
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="title"
                    />
                  </FormControl>
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.SUBTITLE}</FormLabel>
                    <Field
                      name="subtitle"
                      className="form-control"
                      validate={validateTextField}
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="subtitle"
                    />
                  </FormControl>
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.IMAGE}</FormLabel>
                    <Field
                      name="image"
                      accept="image/*"
                      type="file"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="image"
                    />
                  </FormControl>
                  <FormControl className="margin-bottom-35" required>
                    <FormLabel>{GeneralStrings.FORM.CONTENT}</FormLabel>
                    <Field name="content">
                      {({ field }) => (
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          initialValue="<p>Enter blog post here...</p>"
                          init={{
                            height: 400,
                            menubar: true,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          onEditorChange={(e) => {
                            handleChange({
                              target: { name: "content", value: e },
                            });
                          }}
                          textareaName="content"
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          value={values.content}
                        />
                      )}
                    </Field>
                  </FormControl>
                  <div className="flex flex-row">
                    <Button
                      color="primary"
                      className="text-lighter margin-left-auto margin-bottom-25"
                      onClick={toggleCancelModal}
                    >
                      {GeneralStrings.FORM.CANCEL}
                    </Button>
                    <Button
                      type="submit"
                      color="success"
                      disabled={isSubmitting}
                      className="text-lighter margin-left-25 margin-bottom-25"
                    >
                      {GeneralStrings.FORM.SUBMIT}
                      {isSubmitting && (
                        <Spinner
                          color="dark"
                          size="sm"
                          className="margin-left-10"
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <ConfirmCancelModal
          cancelMessage={blogStrings.CANCEL_DESCRIPTION}
          handleCancel={handleCancel}
          isOpen={showCancelModal}
          toggle={toggleCancelModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default AddBlogPost;
